import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { get } from "lodash";
import cx from "classnames";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import EmptyData from "../../components/EmptyData";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query IkonQuery($slug: String!) {
		hasura {
			ikons(where: { slug: { _eq: $slug } }) {
				slug
				type
				title_full
				main_image
				content_blocks
				seo

				ikons_saints {
					saint {
						slug
						type
						title
					}
				}
			}
		}
	}
`;

export default function Ikons({ data }) {
	const hasuraData = data.hasura;

	const ikon = get(hasuraData, "ikons[0]", {});
	const title_full = get(ikon, "title_full", "");
	const imageSrc = get(ikon, "main_image.src", "");
	const content_blocks = get(ikon, "content_blocks", []);
	const ikons_saints = get(ikon, "ikons_saints", []);

	const url = makeUrl.ikon(ikon);
	const slug = get(ikon, "slug", "");

	const { theme } = useThemeContext();

	const needNoIndex = parseInt(slug[slug.length - 1], 10) > 1;

	const modifiedIkon = Object.assign({}, ikon, {
		seo: {
			...get(ikon, "seo", null),
			html: {
				...get(ikon, "seo.html", ""),
				noindex: cx({ noindex: needNoIndex }, { nofollow: needNoIndex }),
			},
		},
	});

	const crumbItem = {
		item: {
			title_full: "Иконы",
			slug: "",
		},
	};

	useEffect(() => {
		removePreloader();
	}, []);

	return ikon ? (
		<Pages entity={modifiedIkon} url={url}>
			<ContentBlock key={"Breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={title_full}
						pageContext={crumbItem}
					/>
				</div>
			</ContentBlock>
			<ContentBlock key={"media-content"}>
				<div className={"container"}>
					<h1 className={"h1"} itemProp="headline" style={removeEmptyKeys({ color: theme.color.text })}>{title_full}</h1>
					<TextBlock className={"d-flex flex-column"}>
						<div className="mb-3 col-12 d-flex justify-content-center">
							{!!imageSrc && <img src={imageSrc} alt={title_full} style={{ objectFit: "contain",
								width: "300px" }}/>}
						</div>
						<div className="col-12">
							{ikons_saints.map(item => <Link key={`saint-link-${get(item, "saint.slug", "")}`} to={makeUrl.saint(get(item, "saint", {}))}>{get(item, "saint.title", "")}</Link>)}
						</div>
					</TextBlock>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<Share url={url} pageTitleFull={title_full} imageSrc={imageSrc} />
		</Pages>
	) : (
		<EmptyData />
	);
}

Ikons.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

Ikons.defaultProps = {
	data: {},
	pageContext: {},
};
